<template>
  <div class="UserHome">
    <!-- <div class="wrapperTop">
      <div class="left">
    <div class="showData">
          <h3 class="index_title">统计分析</h3> -->

    <!-- <div>
      <div v-if="isshow" class="textBox">
        <transition name="slide" @click="tonews">
          <p class="text" :key="text.id">{{ text.val }}</p>
        </transition>
      </div>
    </div> -->
    <div class="bottomDiv1" style="width: 97%; height: auto">
      <div class="index_title" style="border-bottom: none">统计分析</div>
      <div class="formDiv" style="margin: 20px 0 0 0" v-if="isadmin">
        <el-form label-position="right" label-width="70px">
          <el-row :gutter="10">
            <el-col :span="10">
              <el-form-item label="运营商:">
                <el-select
                  v-model="serachList.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  @change="searchBtn()"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="1">
              <div
                class="BtnSearch"
                style="margin-top: 5px"
                @click=""
              >
                查询
              </div>
            </el-col> -->
          </el-row>
        </el-form>
      </div>
      <div class="statistics" style="border-bottom: 3px #f0f0f0 solid">
        <div class="top1">
          <div>
            <router-link to="/device_manage/station_search" class="item">
              <p class="s_title">充电站总数(个)</p>
              <p class="s_water">{{ FormData.length_station }}</p>
            </router-link>
          </div>
        </div>
        <div class="top1">
          <div>
            <router-link to="/device_manage/charg_manage-type=undefined" class="item">
              <p class="s_title">设备总数(个)</p>
              <p class="s_water">{{ FormData.count_device }}</p>
            </router-link>
          </div>
        </div>
        <div class="top1">
          <div>
            <router-link to="" @click="toDevice(0)" class="item">
              <p class="s_title">空闲设备(个)</p>
              <p class="s_water">{{ FormData.length_device }}</p>
            </router-link>
          </div>
        </div>
        <div class="top1">
          <div>
            <router-link to="" @click="toDevice(1)" class="item">
              <p class="s_title">使用中设备(个)</p>
              <p class="s_water">{{ FormData.off_line }}</p>
            </router-link>
          </div>
        </div>
        <div class="top1">
          <div>
            <router-link to="" @click="toDevice(2)" class="item">
              <p class="s_title">异常设备(个)</p>
              <p class="s_water">{{ FormData.file_device }}</p>
            </router-link>
          </div>
        </div>
        <div class="top1">
          <div>
            <router-link to="/user_manage/search_man" class="item">
              <p class="s_title">用户总数</p>
              <p class="s_water">{{ FormData.number_of_ser }}</p>
              <!-- <p class="s_water">0</p> -->
            </router-link>
          </div>
        </div>
      </div>

      <div class="statistics" style="border-bottom: 3px #f0f0f0 solid">
        <div>
          <div>
            <router-link style="color: #ff6060" to="" class="item">
              <p class="s_title">订单总金额(元)</p>
              <p class="s_water">{{ FormData.sum_amount }}</p>
              <!-- <p class="s_water">0</p> -->
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color: #2ed2aa" to="" class="item">
              <p class="s_title">订单服务费(元)</p>
              <p class="s_water">{{ FormData.profit }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color: #29a1ec" to="" class="item">
              <p class="s_title">订单用电费(元)</p>
              <p class="s_water">{{ FormData.utility_bills }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color: #6e39e8" to="" class="item">
              <p class="s_title">钱包充值金额(元)</p>
              <p class="s_water">{{ FormData.Balance_recharge }}</p>
            </router-link>
          </div>
        </div>

        <div>
          <div>
            <router-link style="color: #94bb22" to="" class="item">
              <p class="s_title">充电次数</p>
              <p class="s_water">{{ FormData.employ }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color: #ffa300" to="" class="item">
              <p class="s_title">充电时长(分)</p>
              <p class="s_water">{{ FormData.cost_time }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color: #2ed2aa" to="" class="item">
              <p class="s_title">总用电量(度)</p>
              <p class="s_water">{{ FormData.electric_quantity }}</p>
              <!-- <p class="s_water">0</p> -->
            </router-link>
          </div>
        </div>
      </div>
      <div class="statistics2">
        <div>
          <div>
            <router-link style="color:#2ed2aa" to="" class="item">
              <p class="s_title">当前在线用户数</p>
              <p class="s_water">{{ FormData.off_line }}</p>
              <!-- <p class="s_water">0</p> -->
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color:#ff6060" to="" class="item">
              <p class="s_title">正在充电用户数</p>
              <p class="s_water">{{ FormData.off_line }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color:#2ed2aa" to="" class="item">
              <p class="s_title">正在充电设备数(个)</p>
              <p class="s_water">{{ FormData.off_line }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color:#29a1ec" to="" class="item">
              <p class="s_title">当天充电的总收入(元)</p>
              <p class="s_water">{{ FormData.day_money }}</p>
            </router-link>
          </div>
        </div>

        <div>
          <div>
            <router-link style="color:#6e39e8" to="" class="item">
              <p class="s_title">当天充电用电量(度)</p>
              <p class="s_water">{{ FormData.day_electric }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color:#94bb22" to="" class="item">
              <p class="s_title">当天充电费用(元)</p>
              <p class="s_water">{{ FormData.day_electric_money }}</p>
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <router-link style="color:#ffa300" to="" class="item">
              <p class="s_title">当天营收收入(元)</p>
              <p class="s_water">{{ FormData.count_money }}</p>
              <!-- <p class="s_water">0</p> -->
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="wrapperBottom">
    <div class="echartsBing">
      <div class="">
        <h3 class="index_title1 index_title">充电设备情况</h3>
        <div ref="bing1"></div>
      </div>
      <!-- <div class="" ref="">
          <h3 class="index_title">各渠道征收额占比</h3>
          <div ref="bing2"></div>
        </div> -->
      <div class="echartszhu">
        <h3 class="index_title1 index_title">月收入情况</h3>
        <div ref="zhu" style="width: 650px"></div>
      </div>
    </div>
  </div>

  <div class="bottomDiv" style="width: 96%; height: auto; margin-top: 10px">
    <div class="index_title" style="margin-bottom: none">快捷方式</div>
    <div class="statistics1">
      <div>
        <router-link to="/user_manage/search_man">
          <img src="@/assets/img/yhgl.png" />
          <p style="text-align: center">用户管理</p>
        </router-link>
      </div>
      <div>
        <router-link to="/order_manage/order_current">
          <img src="@/assets/img/ddgj1.png" />
          <p style="text-align: center">订单管理</p>
        </router-link>
      </div>
      <div>
        <router-link to="/device_manage/station_search">
          <img src="@/assets/img/home3.png" />
          <p style="text-align: center">设备管理</p>
        </router-link>
      </div>

      <div>
        <router-link to="/report_manage/invest_collect">
          <img src="@/assets/img/home1.png" />
          <p style="text-align: center">报表管理</p>
        </router-link>
      </div>
      <div>
        <router-link to="/report_manage/expend_collect">
          <img src="@/assets/img/home5.png" />
          <p style="text-align: center">费用管理</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* 标题文字样式 */
.index_title {
  font-size: 18px;
  color: #333;
  border-left: 5px solid #04a0c3;
  padding-left: 25px;
}
.index_title1 {
  font-size: 18px;
  color: #333;
  margin-top: 20px;
  /* border-left: 5px solid; */
  padding-left: 20px;
}
.UserHome {
  padding: 2px 30px 2px 2px;
}

/* 下半部分样式 */
.wrapperBottom {
  /* background-color: #fff; */
  width: 97.7%;
  padding: 0 20px 0px 0px;
}
/* 数据展示结束 */
.echartsBing {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
/* .echartsBing .index_title {
  margin: 20px 0 0 20px;
} */
.echartsBing > div {
  width: calc(50% - 6px);
  height: 320px;
  background: #fff;
  position: relative;
}
.echartsBing > div div {
  width: 80%;
  height: 320px;
  margin-left: 8%;
  position: absolute;
  top: 0;
  left: 0;
}
/* 饼状图结束 */
.echartszhu {
  background: #fff;
  width: 650px;
  height: 260px;
  position: relative;
  /* margin-top: 30px; */
}
.echartszhu .index_title {
  position: relative;
  /* top: 30px; */
}
.bottomDiv {
  background-color: #fff;
  padding: 15px;
  padding-top: 20px;
}
.bottomDiv1 {
  background-color: #fff;
  padding: 20px;
}
.statistics,.statistics2 {
  display: flex;
  width: 100%;
  margin: 0 auto;
  /* justify-content: space-between; */
  flex-wrap: nowrap;
  padding: 0;
}

.statistics1 {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-around;
  flex-wrap: nowrap;
}
.top1 {
  background-image: linear-gradient(#e8fbff, #b3e5ef) !important;

  color: #29a1ec !important;
}
.statistics > div ,.statistics2 > div{
  margin: 10px;
  width: 230px;
  height: 125px;
  background-size: 100% 100%;
  text-align: center;
  line-height: 35px;
}
.statistics > div > div,.statistics2 > div > div {
  margin: 30px 0;
  cursor: pointer;
}
.statistics > div .s_title,.statistics2 > div .s_title {
  font-size: 17px;
  cursor: pointer;
}
.statistics > div .s_water ,.statistics2 > div .s_water{
  font-size: 26px;
  font-weight: bold;
  cursor: pointer;
}
.statistics > div:nth-child(1) {
  background-image: url("../../assets/img/six.png");
  /* color: #ff6060 !important; */
}
.statistics > div:nth-child(2) {
  background-image: url("../../assets/img/four.png");
  /* color: #2ed2aa; */
}
.statistics > div:nth-child(3) {
  background-image: url("../../assets/img/five.png");
  /* color: #29a1ec !important; */
}
.statistics > div:nth-child(4) {
  background-image: url("../../assets/img/three.png");
  /* color: #6e39e8; */
}
.statistics > div:nth-child(5) {
  background-image: url("../../assets/img/two.png");
  /* color: #94bb22; */
}
.statistics > div:nth-child(6) {
  background-image: url("../../assets/img/one.png");
  /* color: #ffa300; */
}
.statistics > div:nth-child(7) {
  background-image: url("../../assets/img/four.png");
  /* color: #2ed2aa; */
}
.statistics2 > div:nth-child(2) {
  background-image: url("../../assets/img/six.png");
  color: #ff6060 !important;
}
.statistics2 > div:nth-child(3) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
}
.statistics2 > div:nth-child(4) {
  background-image: url("../../assets/img/five.png");
  color: #29a1ec !important;
}
.statistics2 > div:nth-child(5) {
  background-image: url("../../assets/img/three.png");
  color: #6e39e8;
}
.statistics2 > div:nth-child(6) {
  background-image: url("../../assets/img/two.png");
  color: #94bb22;
}
.statistics2 > div:nth-child(7) {
  background-image: url("../../assets/img/one.png");
  color: #ffa300;
}
.statistics2 > div:nth-child(1) {
  background-image: url("../../assets/img/four.png");
  color: #2ed2aa;
}
.statistics1 > div {
  width: 200px;
  height: 150px;
  font-size: 17px;
  line-height: 30px;
  text-align: center;
}
.statistics1 img {
  width: 180px;
  height: 100px;
}
h2 {
  padding: 20px 0;
}
.textBox {
  background-color: rgb(250, 245, 235);
  width: 100%;
  /* height: 46px; */
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  margin: 5px auto;
  overflow: hidden;
  position: relative;
  text-align: center;
}
.text {
  margin-left: 10px;
  position: absolute;
  bottom: 0;
  color: orange;
  line-height: 30px;
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0s linear;
}
.slide-enter {
  transform: translateY(20px) scale(1);
  opacity: 1;
}
.slide-leave-to {
  transform: translateY(-20px) scale(0.8);
  opacity: 0;
}
</style>

<script>
// import { onMounted, reactive, toRefs, onBeforeUnmount, ref } from "vue-demi";
import { onMounted, reactive, toRefs, ref } from "vue-demi";
import { home_data_summary, fault_message, operator_data } from "@/request/api";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import { ElMessage, ElNotification } from "element-plus";
// import { Vue3SeamlessScroll } from "vue3-seamless-scroll";
export default {
  name: "UserHome",
  data() {
    return {
      isshow: false,
      textArr: [],
      textArr1: [],
      textArr2: [],
      number: 0,
      // FormData:{
      // }
    };
  },
  computed: {
    text() {
      return {
        id: this.number,
        val: `${this.textArr[this.number]} ${this.textArr1[this.number]} ${
          this.textArr2[this.number]
        }`,
        // val1: this.textArr1[this.number],
        // val2: this.textArr2[this.number],
      };
    },
  },
  created() {},
  mounted() {
    setTimeout(() => {
      if (!this.isadmin) {
        this.getList1();
      }
    }, 3000);
  },
  methods: {
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      }

      // 为了确保不会切断单词，我们可以从后往前截取，直到找到一个空格
      let truncatedText = text.substr(0, maxLength);
      let lastIndexOfSpace = truncatedText.lastIndexOf(" ");

      // 如果没有找到空格，则直接截取到maxLength
      if (lastIndexOfSpace === -1) {
        truncatedText = text.substring(0, maxLength);
      } else {
        truncatedText = truncatedText.substring(0, lastIndexOfSpace);
      }

      // 添加省略号
      return truncatedText + "...";
    },
    getList1() {
      ElNotification.closeAll(); // 跳转前关闭通知
      let that = this;
      const dataa1 = {
        operator_id: sessionStorage.getItem("userID"),
        work_type: 0,
        page: 1,
      };
      fault_message(dataa1).then((res) => {
        if (res) {
          if (res.code == 200) {
            if (
              res.data != "" &&
              res.data.length != 0 &&
              res.data != null &&
              res.data != undefined
            ) {
              that.isshow = true;
            } else {
              that.isshow = false;
            }
            res.data.forEach((a, index) => {
              if (index <= 2) {
                // this.textArr[index] = {
                //   station_name: a.station_name,
                //   device_id: a.device_id,
                //   status: a.status,
                // };
                ElNotification({
                  title:
                    that.truncateText(a.station_name, 6) + " " + a.device_id,
                  message: a.status + "，请点击处理！",
                  type: "warning",
                  duration: 0,
                  onClick: that.toTaskCenter,
                  offset: (index + 1) * 100,
                });
              }
            });
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    },
    toTaskCenter() {
      ElNotification.closeAll(); // 跳转前关闭通知
      this.$router.push({ path: "/fault_manage/fault_manage" });
    },
    // startMove() {
    //   // eslint-disable-next-line
    //   let timer = setTimeout(() => {
    //     if (this.number == this.textArr.length - 1) {
    //       this.number = 0;
    //     } else {
    //       this.number += 1;
    //     }
    //     this.startMove();
    //   }, 5000); // 滚动不需要停顿则将2000改成动画持续时间
    // },
  },
  // components: {
  //   Vue3SeamlessScroll,
  // },
  setup() {
    const data1 = reactive({
      FormData: {},
      serachList: {
        orderTime: [],
      },
      value2: "",
      FormData1: [],
      FormData2: [],
      FormData3: [],
      FormData4: [],
      value3: 0,
      isadmin: false,
      users: [],
    });
    const router = useRouter();
    const echart = echarts;
    onMounted(() => {
      getList();

      getUsername();
      setTimeout(() => {
        initBing1();
        initZhu();
      }, 2500);
    });
    const toDevice = (state) => {
      sessionStorage.setItem("state", state);
      router.push({
        path: "/device_manage/charg_manage-type=undefined",
      });
    };
    const changeMonth = (value) => {
      if (value != null && value != undefined && value != "") {
        console.log(value, "valuevalue");
        let date = new Date(value);
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let year = date.getFullYear();
        let day = new Date(year, month, 0);
        data1.serachList.orderTime[0] = year + "-" + month + "-01";
        data1.serachList.orderTime[1] =
          year + "-" + month + "-" + day.getDate();
      } else {
        data1.serachList.orderTime[0] = "";
        data1.serachList.orderTime[1] = "";
      }
    };
    const changeType = () => {
      data1.serachList.orderTime = [];
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data1.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data1.isadmin = true;
        } else {
          data1.isadmin = false;
          // getList1();
        }
      });
    };
    const searchBtn = () => {
      getList();
    };
    const getList = () => {
      data1.FormData1 = [];
      data1.FormData2 = [];
      data1.FormData3 = [];
      data1.FormData4 = [];
      let dataa;
      //  if (data1.serachList.orderTime) {
      //   dataa = {
      //     start_time: data1.serachList.orderTime[0]
      //       ? data1.serachList.orderTime[0]
      //       : "",
      //     end_time: data1.serachList.orderTime[1]
      //       ? data1.serachList.orderTime[1]
      //       : "",
      //     username: localStorage.getItem("username"),
      //   };
      // } else if (
      //   data1.serachList.orderTime == null ||
      //   data1.serachList.orderTime == undefined
      // ) {
      //   dataa = {
      //     username: localStorage.getItem("username"),
      //     start_time:"",
      //     end_time:""
      //   };
      // }
      if (data1.isadmin) {
        const username =
          data1.serachList.username != null &&
          data1.serachList.username != undefined &&
          data1.serachList.username != ""
            ? data1.serachList.username
            : localStorage.getItem("username");
        dataa = {
          username: username,
          start_time: "",
          end_time: "",
        };
      } else {
        dataa = {
          username: localStorage.getItem("username"),
          start_time: "",
          end_time: "",
        };
      }

      console.log(dataa);
      home_data_summary(dataa).then((res) => {
        if (res) {
          data1.FormData.cost_time = Number(res.data.cost_time)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.sum_amount = Number(res.data.sum_amount)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.electric_quantity = Number(res.data.electric_quantity)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.utility_bills = Number(res.data.utility_bills)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.employ = res.data.employ.toLocaleString();
          data1.FormData.Balance_recharge = Number(res.data.Balance_recharge)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.number_of_ser =
            res.data.number_of_ser.toLocaleString();

          data1.FormData.profit = Number(res.data.profit)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.length_station =
            res.data.length_station.toLocaleString();
          data1.FormData.length_device =
            res.data.length_device.toLocaleString();
          data1.FormData.off_line = res.data.off_line.toLocaleString();
          data1.FormData.count_device = res.data.count_device.toLocaleString();
          data1.FormData.file_device = res.data.file_device.toLocaleString();
           data1.FormData.day_electric = Number(res.data.day_electric)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          data1.FormData.day_money = Number(res.data.day_money)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            data1.FormData.day_electric_money = Number(res.data.day_electric_money)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
             data1.FormData.count_money = Number(res.data.count_money)
            .toFixed(2)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          var a = {};
          var b = {};
          var c = {};

          a["value"] = Number(res.data.free_device);
          b["value"] = Number(res.data.file_free_device);
          c["value"] = Number(res.data.off_line);
          a["name"] = "空闲设备";
          b["name"] = "异常设备";
          c["name"] = "使用中设备";
          data1.FormData1.push(a, b, c);
        }
        res.home_view.forEach((dd) => {
          data1.FormData2.push(dd.charge_amount.toFixed(2));
          data1.FormData3.push(dd.num.toFixed(2));
          data1.FormData4.push(dd.sum_num.toFixed(2));
        });

        for (let i = 0; i < 12 - res.home_view.length; i++) {
          data1.FormData2.unshift("0");
          data1.FormData3.unshift("0");
          data1.FormData4.unshift("0");
        }
        setTimeout(() => {
          initBing1();
          initZhu();
        }, 500);
      });
    };
    const bing1 = ref(null);
    const initBing1 = () => {
      const mapwarp = echart.init(bing1.value);

      const option = {
        tooltip: {
          backgroundColor: "rgba(0,0,0,.5)",
          textStyle: {
            color: "#bbb",
          },
          trigger: "item",
          formatter: "{b}: {c}个 ({d}%)",
          position: function (p) {
            //其中p为当前鼠标的位置
            return [p[0] + 10, p[1] - 10];
          },
        },
        legend: {
          orient: "vertical",
          right: "20%",
          top: "10%",
          itemWidth: 10,
          itemHeight: 10,
          data: ["空闲设备", "异常设备", "使用中设备"],
          textStyle: {
            color: "#333",
            // fontSize: "16",
          },
        },

        series: [
          {
            type: "pie",
            center: ["36%", "60%"],
            radius: ["30%", "60%"],
            label: { show: false },
            labelLine: { show: true },
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,

              formatter(param) {
                // correct the percentage
                return (
                  param.name +
                  "：" +
                  param.value +
                  "个 (" +
                  param.percent +
                  "%)"
                );
              },
            },
            data: JSON.parse(JSON.stringify(data1.FormData1)),

            // data: getsss
          },
        ],
      };
      mapwarp.setOption(option);
      window.addEventListener("resize", function () {
        mapwarp.resize();
      });
    };
    // 柱状图
    const zhu = ref(null);
    let xData = (function () {
      let data = [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ];
      const today = new Date();
      let month = today.getMonth();
      console.log(data.length);
      for (let i = 0; i < data.length; i++) {
        if (i === month) {
          data.splice(
            0,
            data.length,
            ...data.slice(i + 1, data.length).concat(...data.slice(0, i + 1))
          );
        }
      }
      return data;
    })();
    const initZhu = () => {
      const mapwarp = echart.init(zhu.value);
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          // formatter: function (params) {
          //   console.log(params);
          //   return (
          //     params[0].seriesName +
          //     "<br/>" +
          //     params[0].name +
          //     ": " +
          //     params[0].value.toFixed(2)
          //   );
          // },
          formatter(params) {
            var relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              const money = params[i].value
                ? Number(params[i].value)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "0.00";
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                ": " +
                `<span style="float:right">${money} 元</span>`;
            }
            return relVal;
          },
        },
        legend: {
          // orient: "vertical",
          right: "10%",
          top: "10%",
          textStyle: {
            color: "#333",
            // fontSize: "18",
          },
        },
        grid: {
          top: "30%",
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: xData,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        series: [
          {
            name: "充值收入",
            type: "bar",
            data: data1.FormData2,
          },
          {
            name: "临时充电收入",
            type: "bar",
            data: JSON.parse(JSON.stringify(data1.FormData3)),
          },
          {
            name: "累计收益",
            type: "line",
            data: JSON.parse(JSON.stringify(data1.FormData4)),
          },
        ],
      };
      mapwarp.setOption(option);
      window.addEventListener("resize", function () {
        mapwarp.resize();
      });
    };
    return {
      ...toRefs(data1),
      changeMonth,
      searchBtn,
      // getList,
      bing1,
      // bing2,
      zhu,
      changeType,
      toDevice,
      getUsername,
    };
  },
};
</script>
